import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Route, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import { Map } from 'immutable';
import { compose } from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';

import { types as AppTypes, cirrusSkins, selectPageTitle, selectPageTitleId, orgHasLicense } from '../../reducers/app';
/* Core Layout components seen on every page */
import DefaultAppBar from './appBar';
import SideNav from './sideNav';

import ModalConductor from '../ModalConductor/modalConductor';
/* Import new features here */
import SuccessSnackbar from '../../components/SuccessSnackbar/SuccessSnackbar';

import LicensingServiceApi from '../../apis/licensingServiceApi';
import { actions as AppActions, selectCerEnabled, selectInformaCastEnabled, selectEmergencyCallAssistEnabled } from '../../reducers/app';
import { selectUserRole, selectUserCompany, selectUser, changeSubtenant, currentCompanyLevel, logout, currentCompanyOrgType, orgTypes, selectOriginalLoginCompany } from '../../reducers/auth';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import PageErrorMessage from '../../containers/PageErrorMessage/pageErrorMessage';
import history from '../../utils/history';

import AttLogo from '../../assets/att-logo.png';
import './defaultLayout.css';
import CompanyFeaturesAPI from '../../apis/companyFeaturesApi';
import UsersAPI from '../../apis/usersApi';
import KeysApi from '../../apis/keysApi';

import { routeAndNavData, routeAndNavCheck } from './routeAndNavData';
import { Helmet } from "react-helmet";
import Five9Favicon from '../../assets/five9-favicon.ico';
import DefaultFavicon from '../../assets/favicon.ico';
import DarkFavicon from '../../assets/favicon-dark.ico';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        position: 'relative',
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
    },
    contentWithSidebar: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        padding: theme.spacing.unit * 3,
        marginTop: '0em',
        paddingLeft: 5,
    },
    item: {
        paddingLeft: 3 * theme.spacing.unit,
    },
    group: {
        fontWeight: 'bold',
        opacity: 1,
    }
});

const DefaultLayout = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [attSkin, setAttSkin] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);

    const permifyUser = JSON.parse(sessionStorage.getItem('__permifyUser'));
    
    const googleApiKey = sessionStorage.getItem('googleApiKey');

    const [permifyUserLoaded, setPermifyUserLoaded] = useState(permifyUser ? true : false);

    const open = Boolean(anchorEl);
    let displayMenu = false;

    let company = props.company.toJS();
    let role = props.userRole.toJS();

    if(!company)
        company = Map();
    if(!role)
        role = Map();

    const breadcrumbsPath = useBreadcrumbs([], company, role, props.originalLoginCompany);
    if (breadcrumbsPath.length > 1) {
        displayMenu = true;
    }

    function switchBreadcrumbOrganization(organization) {
        if(organization.id !== company.id) {
            props.changeSubtenant(organization);
            // TODO: update company features?
            handleClose();
            history.push({
                pathname: '/'
            });
        }
    }

    useEffect(() => {
        if (!googleApiKey) {
            KeysApi.getGoogleApiKey().then(response => {
                sessionStorage.setItem('googleApiKey', response.data.key);
            });   
        }
    }, []);
    
    useEffect(() => {
        const rolesList = [];
        const permissionsList = [];
        const user = props.user.toJS();
        const roleName = user.role.name.replace(/ADMIN$/, 'ADMINISTRATOR');

        if (!permifyUser) {
            UsersAPI.getUserPermissions().then(response => {
                response.data.forEach(permission => {
                    const permissions = `${permission.action}_${permission.asset}`;
                    permissionsList.push(permissions);
                });

                if (user) {
                    rolesList.push(roleName);
                    user.roles = rolesList;
                    user.permissions = permissionsList;
                    sessionStorage.setItem('__permifyUser', JSON.stringify(user));
                    setPermifyUserLoaded(true); 
                }
            });   
        }
    }, []);

    useEffect(() => {
        const paginationInputField = document.querySelector('.ReactTable input[type=number]');
        
        if (paginationInputField) {
            paginationInputField.addEventListener('input', (event) => {
                if (event.target.value) {
                    paginationInputField.blur();
                    paginationInputField.focus();
                }
            });
        }

        const bodyEl = document.querySelector('body');

        bodyEl.classList.remove('overlay-nav');
        
        props.setPageSize(25);

        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setIsDarkMode(true);
        }
    }, [history.location.pathname]);

    useEffect(() => {
        if (window.addEventListener) {
            // Normal browsers
            window.addEventListener("storage", handler, false);
          } else {
            // for IE (why make your life more difficult)
            window.attachEvent("onstorage", handler);
          };
          
          function handler(e) {
              if (e.key === 'logout') {
                if(!e.newValue) return;
                props.logout();
            }
          }
    }, []);

    useEffect(() => {
        if (company.id) {
            LicensingServiceApi.checkIfOrgHasLicense(company.id).then(response => {
                props.orgHasLicense(response.data);
            });
        } else {
            props.orgHasLicense(false);
        }

        // add company features logic
        if (company.id) {
            CompanyFeaturesAPI.getCompanyFeatures(company.id).then(response => {
                props.setCompanyFeatures(response.data);
            })
        }
        else {
            props.setCompanyFeatures([]);
        }
    }, [company.id]);

    useEffect(() => {
        if (props.cirrusSkins === 'att') {
            setAttSkin(true);
        } else {
            setAttSkin(false);
        }
        
    }, [props.cirrusSkins]);

    function handleMenu(event) {
		setAnchorEl(event.currentTarget);
	};

	function handleClose() {
		setAnchorEl(null);
    };

    useEffect(() => {
        const mainContentElm = document.getElementById('main-content');

        if (mainContentElm) {
            mainContentElm.focus();
        }
    }, []);

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        if (event.matches) {
            setIsDarkMode(true);
        } else {
            setIsDarkMode(false);
        }
    });

    return(
        permifyUserLoaded ?
        <div className={props.classes.root}>
            {sessionStorage.getItem('skin') === 'five9' ?
                <Helmet>
                    <title>Five9</title>
                    <link rel="shortcut icon" href={Five9Favicon}></link>
                </Helmet>
                : isDarkMode ? 
                <Helmet>
                    <link rel="shortcut icon" href={DarkFavicon}></link>
                    <title>{document.querySelector('title').getAttribute('data-default') + ' - ' + props.pageTitle}</title>
                </Helmet>
                :
                <Helmet>
                    <link rel="shortcut icon" href={DefaultFavicon}></link>
                    <title>{document.querySelector('title').getAttribute('data-default') + ' - ' + props.pageTitle}</title>
                </Helmet>
            }
            <div className={props.classes.appFrame}>
                <PageErrorMessage />
                <main className={props.classes.contentWithSidebar}>
                    <SideNav /> 
                    <div id="main-content" tabIndex={-1} className={props.classes.content} style={{ outline: '0'}}>
                        <div className="section-wrapper">
                            <DefaultAppBar title="Redsky Admin Portal *PROTOTYPE*" />
                            <div id="company-level" className="company-name">
                            {!displayMenu ? 
                                <span className="org-name">{props.currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM ? 'System' : company.name}</span>
                                :
                                <Button
                                    id="org-level-menu-button"
                                    aria-owns={open ? 'org-level-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    {company.name ? company.name : 'System'}<ArrowDropDown/>
                                </Button>
                            }
                            <Menu
                                id="org-level-menu"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}>
                                {
                                    breadcrumbsPath.map(
                                    (organization, i) => {
                                        return (
                                        <MenuItem 
                                            id={`org-level-item-${i}`}
                                            className={props.classes.item} 
                                            // style={{ padding: 0, height: 'auto' }}
                                            key={i}
                                            onClick={() => switchBreadcrumbOrganization(organization)}>
                                                <span
                                                    // style={{ padding: '11px 16px', display: 'block', width: '100%', textDecoration: 'none' }}
                                                    // color="inherit"  
                                                    >
                                                        {organization.name}
                                                </span>
                                        </MenuItem>
                                        )
                                    }
                                )   
                                }
                            </Menu>
                        </div>
                        </div>
                        <div className="logo-wrapper">
                            {attSkin ?
                                <img src={AttLogo} alt="ATT Logo"/>
                                :
                                <span className="logo"></span>
                            }
                        </div>
                        <h1 id={props.pageTitleId} data-sup="TM">{props.pageTitle}</h1>
                        <Switch>
                            {routeAndNavData.map((item, index) => {
                                if (routeAndNavCheck(item, props)) {
                                    return '';
                                }
                                 else {
                                    return <Route key={index} exact path={item.path} component={item.component}/>;
                                }
                            })
                            }
                        </Switch>
                    </div>
                    <ModalConductor/>
                    <SuccessSnackbar/>
                </main>
            </div>
        </div>
        : <div><LinearProgress /><p>Loading Resources...</p></div>
    );
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
    company: selectUserCompany(),
    userRole: selectUserRole(),
    pageTitle: selectPageTitle(),
    pageTitleId: selectPageTitleId(),
    hasLicense: orgHasLicense(),
    currentCompanyLevel: currentCompanyLevel(),
    cirrusSkins: cirrusSkins(),
    cerEnabled: selectCerEnabled(),
    informaCastEnabled: selectInformaCastEnabled(),
    emergencyCallAssistEnabled: selectEmergencyCallAssistEnabled(),
    currentCompanyOrgType: currentCompanyOrgType(),
    originalLoginCompany: selectOriginalLoginCompany()
});


const mapDispatchToProps = dispatch => {
    return { 
        changeSubtenant: (company) => dispatch(changeSubtenant(company)),
        orgHasLicense: (hasLicense) => dispatch(AppActions.orgHasLicense(hasLicense)),
        setModalClose: () => dispatch(AppActions.setModalClose()),
        logout: (error) => dispatch(logout(error)),
        setCompanyFeatures: (companyFeatures) => dispatch(AppActions.setCompanyFeatures(companyFeatures)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
    }
};

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(DefaultLayout);
