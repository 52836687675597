import React, { useState, useEffect, useRef } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { makeSelectOrganizationsList, organizationsLoading, makeSelectOrganizationsPageCount } from '../../reducers/organizations';
import { changeSubtenant, selectUserRole, currentCompanyOrgType, orgTypes } from '../../reducers/auth';
import { actions as AppActions } from '../../reducers/app';
import { actions as OrgActions } from '../../reducers/organizations';
import OrganizationEditMenu from '../../containers/OrganizationEditMenu/organizationEditMenu';
import ReactTable from 'react-table';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import './organizationsTable.css';
import './organizations.css';
import { Button } from '@material-ui/core';
import history from '../../utils/history';
import { ReactComponent as ServiceProviderIcon } from '../../assets/service-provider.svg';
import { ReactComponent as CustomerIcon } from '../../assets/customer.svg';
import { ReactComponent as ResellerIcon } from '../../assets/reseller.svg';


function OrganizationsTable(props) {
    const [filteredList, setFilteredList] = useState([]);
    const [columns, setColumns] = useState([]);
    
    const user = JSON.parse(sessionStorage.getItem('__permifyUser'));
	const isView = user && user.permissions.includes('VIEW_LICENSING');

    useEffect( () => {

        if(props.searchData) {
            let topLevelList = [];
            
            props.organizations.forEach(org => {
                if (org.parentId === null) {
                    org.subtenants = [];
                    
                    topLevelList.push(org);
                    
                    const index = props.organizations.indexOf(org);
                    if (index > -1) {
                        props.organizations.splice(index, 1);
                    }
                }
            });
            
            props.organizations.forEach(subtenant => {
                topLevelList.forEach(topLevelOrg => {
                    if (subtenant.parentId === topLevelOrg.id) {
                        topLevelOrg.subtenants.push(subtenant);
                    }
                })
            });
            
            setFilteredList(topLevelList);
        }
        else {
            setFilteredList([]);
        }

    }, [props.organizations, props.searchData]);

    useEffect( () => {
        const buildColumns = [];
        
        buildColumns.push({
            Header: '',
            id: 'icon',
            width: 75,
            Cell: row => row.original.orgType === orgTypes.CUSTOMER ? 
                        <CustomerIcon tabIndex={0} title='Customer' className={sessionStorage.getItem('skin') === 'five9' ? 'customer-icon five9' : 'customer-icon'} aria-hidden={true} /> 
                        : row.original.orgType === orgTypes.RESELLER ?
                        <ResellerIcon tabIndex={0} title='Reseller' className={sessionStorage.getItem('skin') === 'five9' ? 'reseller-icon five9' : 'reseller-icon'} aria-hidden={true} /> 
                        :
                        <ServiceProviderIcon tabIndex={0} title='Service Provider' className={sessionStorage.getItem('skin') === 'five9' ? 'service-provider-icon five9' : 'service-provider-icon'} aria-hidden={true} />         
        });

        buildColumns.push({
            Header: <div id="organization-name">Name</div>,
            id: 'name',
            sortable: true,
            Cell: row => (
                <Button id={`name-button-${row.rowId}`} style={{ textTransform: 'initial' }} title={row.original.name} onClick={() => {
                    props.changeSubtenant(row.original);
                    history.push({
                        pathname: '/'
                    });
                    }}>
                    {row.original.name}
                </Button>
            )
        });

        buildColumns.push({
            Header: <div id ="external-organization-id"> External Organization ID / Key</div>,
            id: 'external-organization-id',
            minWidth: 120,
            Cell: row => <div style = {{ padding: '9px 0 0 7px'}} title={row.original.externalOrgId}>{row.original.externalOrgId}</div>
        })
    
        buildColumns.push({
            Header: <div id="organization-description">Description</div>,
            id: 'description',
            Cell: row => <div style={{ padding: '9px 0 0 7px' }} title={row.original.description}>{row.original.description}</div>
        })
        
        buildColumns.push({
            Header: <div id="organization-customers">Customers</div>,
            id: 'customers',
            Cell: row => (
                row.original.orgType === 'CUSTOMER' ? 
                <div style={{ padding: '9px 0 0 7px' }}>N/A</div>
                :
                <Button id={`customers-button-${row.rowId}`} style={{ textTransform: 'initial' }} title={row.original.companyStats ? row.original.companyStats.numOfSubTenants : ''} onClick={() => {
                    props.changeSubtenant(row.original);
                    window.location.reload();
                    }}>
                    {row.original.companyStats ? row.original.companyStats.numOfSubTenants : ''}
                </Button>
            )
        })
        
        buildColumns.push({
            Header: <div id="organization-users">Users</div>,
            id: 'users',
            minWidth: 60,
            Cell: row => (
                row.original.orgType === 'CUSTOMER' ? 
                <Button id={`users-button-${row.rowId}`} style={{ textTransform: 'initial' }} title={row.original.companyStats ? row.original.companyStats.numOfDeviceUsers : ''} onClick={() => {
                    props.setPage(0)
                    props.changeSubtenant(row.original);
                    history.push({
                        pathname: '/users'
                    });
                    }}>
                    {row.original.companyStats ? row.original.companyStats.numOfDeviceUsers : ''}
                </Button>
                :
                <div style={{ padding: '9px 0 0 7px' }} title={row.original.companyStats ? row.original.companyStats.numOfDeviceUsers : ''}>{row.original.companyStats ? row.original.companyStats.numOfDeviceUsers : ''}</div>
            )
        })
        
        buildColumns.push({
            Header: <div id="organization-locations">Locations</div>,
            id: 'locations',
            Cell: row => (
                row.original.orgType === 'CUSTOMER' ? 
                <Button id={`locations-button-${row.rowId}`} style={{ textTransform: 'initial' }} title={row.original.companyStats ? row.original.companyStats.numOfLocations : ''} onClick={() => {
                    props.setPage(0)
                    props.changeSubtenant(row.original);
                    history.push({
                        pathname: '/locations'
                    });
                    }}>
                    {row.original.companyStats ? row.original.companyStats.numOfLocations : ''}
                </Button>
                :
                <div style={{ padding: '9px 0 0 7px' }} title={row.original.companyStats ? row.original.companyStats.numOfLocations : ''}>{row.original.companyStats ? row.original.companyStats.numOfLocations : ''}</div>
                
            )
        })
        
        buildColumns.push({
            Header: <div id="organization-held-devices">HELD Devices</div>,
            id: 'held-devices',
            minWidth: 80,
            Cell: row => (
                row.original.orgType === 'CUSTOMER' ? 
                <Button id={`held-devices-button-${row.rowId}`} style={{ textTransform: 'initial' }} title={row.original.companyStats ? row.original.companyStats.numOfHeldDevices : ''} onClick={() => {
                    props.setPage(0)
                    props.changeSubtenant(row.original);
                    history.push({
                        pathname: '/held-devices'
                    });
                    }}>
                    {row.original.companyStats ? row.original.companyStats.numOfHeldDevices : ''}
                </Button>
                :
                <div style={{ padding: '9px 0 0 7px' }} title={row.original.companyStats ? row.original.companyStats.numOfHeldDevices : ''}>{row.original.companyStats ? row.original.companyStats.numOfHeldDevices : ''}</div>
                
            )
        })
        
        buildColumns.push({
            Header: <div id="organization-licensing">Licensing</div>,
            id: 'licensing',
            Cell: row => (
                row.original.orgType !== 'CUSTOMER' ? 
                <div style={{ padding: '9px 0 0 7px' }}>N/A</div> 
                : row.original.orgType === 'CUSTOMER' && (isView || (props.originalLoginCompany.toJS().orgType === 'SERVICE_PROVIDER' && props.originalLoginCompany.toJS().usageBasedLicensing)) ?
                    <Button id={`licensing-button-${row.rowId}`} style={{ textTransform: 'initial', textAlign: 'left', display: 'block' }} onClick={() => {
                        history.push({
                            pathname: '/manage-licenses/' + row.original.id,
                            search: '?licenseModel=' + row.original.licenseModel
                        });
                        }}>
                        {row.original.managerDataSync && <div style={{ marginBottom: 5, lineHeight: 1.3 }}>E911 Manager ELIN: {row.original.licensingStats ? row.original.licensingStats['E911 Manager ELIN'] : ''}</div>}
                        <div style={{ marginBottom: 5, lineHeight: 1.3 }}>Enhanced Notification: {row.original.licensingStats ? row.original.licensingStats['Enhanced Notification'] : ''}</div>
                        {!row.original.usageBasedLicensing && <div style={{ marginBottom: 5, lineHeight: 1.3 }}>Location: {row.original.licensingStats ? row.original.licensingStats['Location'] : ''}</div> }
                        {!row.original.usageBasedLicensing && <div style={{ marginBottom: 5, lineHeight: 1.3 }}>User: {row.original.licensingStats ? row.original.licensingStats['User'] : ''}</div>}
                    </Button>
                :
                    <div>
                        {row.original.managerDataSync && <div style={{ marginBottom: 5, lineHeight: 1.3 }}>E911 Manager ELIN: {row.original.licensingStats ? row.original.licensingStats['E911 Manager ELIN'] : ''}</div>}
                        <div style={{ marginBottom: 5, lineHeight: 1.3 }}>Enhanced Notification: {row.original.licensingStats ? row.original.licensingStats['Enhanced Notification'] : ''}</div>
                        {!row.original.usageBasedLicensing && <div style={{ marginBottom: 5, lineHeight: 1.3 }}>Location: {row.original.licensingStats ? row.original.licensingStats['Location'] : ''}</div>}
                        {!row.original.usageBasedLicensing && <div style={{ marginBottom: 5, lineHeight: 1.3 }}>User: {row.original.licensingStats ? row.original.licensingStats['User'] : ''}</div>}
                    </div>
                )
        })
    
        buildColumns.push(
            {
                Header: '',
                width: 65,
                id: 'edit-menu',
                Cell: row => (
                    <OrganizationEditMenu row={row} 
                      setCurrentModal={props.setCurrentModal} 
                      pageSize={props.pageSize} 
                      page={props.page}
                      currentSortColumn={props.currentSortColumn}
                      checkCustomPermission={props.checkCustomPermission}
                      user={props.user} />
                )
            }
        );
        
        setColumns(buildColumns);
        
    },[filteredList, props.organizations]);

    const {getOrganizations} = props;

    useEffect(() => {
        if (props.organizations && props.organizations.toArray().length === 0 && props.page > 0 && props.organizationsPageCount < props.page+1) {
            props.setPage(props.page-1)
        }
    }, [props.organizations && props.organizations.toArray().length === 0 && props.page > 0 && props.organizationsPageCount < props.page+1]);

    useEffect( () => {
        if (props.pageSize) {
            getOrganizations(props.page + 1, props.pageSize, props.searchValue, props.currentSortColumn.id, props.currentSortColumn.desc ? 'DESC': 'ASC');
            props.setTableProps({
                page: props.page+1, 
                pageSize: props.pageSize, 
                sortBy: props.currentSortColumn.id, 
                direction: props.currentSortColumn.desc ? 'DESC': 'ASC'
            });
        }
    }, [props.currentSortColumn, props.pageSize, props.page]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }      

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [props.loading]);

    return (
        <ReactTable
            resizable={false}
            sortable={false} 
            className="-striped -highlight org-table"
            showPaginationBottom={true}
            data={filteredList.length > 0 ? filteredList : props.organizations.toArray()}
            pages={props.organizationsPageCount <= 0 ? 1 : props.organizationsPageCount}
            page={props.page}
            minRows={props.organizations && props.organizations.length > 0 ? 0 : 5}
            defaultPageSize={25}
            columns={columns}
            manual
            loading = {props.loading}
            onPageSizeChange={(pageSize) => {
                props.setPageSize(pageSize);
                props.setPage(0);
                resetScrollInsideTable();
            }}
            sorted={props.sortColumns}
            onSortedChange={(newSorted, column, shiftKey) => {
                let sortedArray = [...props.sortColumns];
                let currentSortColumn;
                sortedArray.forEach( (columnObject, index) => {                    
                    if(column.id === columnObject.id) {
                        // state needs updated object reference, doesn't detect nested fields
                        currentSortColumn = {...columnObject};
                        currentSortColumn.desc = !currentSortColumn.desc;
                        sortedArray[index] = currentSortColumn;
                    }
                    else {
                        let resetColumn = {...columnObject};
                        resetColumn.desc = false;
                        sortedArray[index] = resetColumn;
                    }
                    props.setCurrentSortColumn(currentSortColumn);
                    props.setSortColumns(sortedArray);
                })       
            }}
            onPageChange={(pageIndex) => {
                props.setCurrentPage(pageIndex + 1);
                props.setPage(pageIndex);
                resetScrollInsideTable();
            }}
            LoadingComponent={ReactTableLoadingComponent}
            getRowId={(row, relativeIndex) => {
            }}
            getTrProps={
                (state, rowInfo, column) => {

                    if(rowInfo) {
                        rowInfo.rowId = 'org-row-' + rowInfo.original.id;

                        return {
                            id: rowInfo.rowId,
                        };
                    } else {
                      return {};
                    }
                }
            }
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
    organizations: makeSelectOrganizationsList(),
    loading: organizationsLoading(),
    organizationsPageCount: makeSelectOrganizationsPageCount(),
    userRole: selectUserRole(),
    currentCompanyOrgType: currentCompanyOrgType()
});

const mapDispatchToProps = dispatch => {
    return {
        getOrganizations: (page, pageSize, searchValue, sortBy, sortDirection) => dispatch(OrgActions.getOrganizationsRequest(page, pageSize, searchValue, sortBy, sortDirection)),
        changeSubtenant: (company) => dispatch(changeSubtenant(company)),
        setPageSize: (pageSize) => dispatch(AppActions.setPageSize(pageSize)),
        setCurrentPage: (currentPage) => dispatch(AppActions.setCurrentPage(currentPage)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsTable);