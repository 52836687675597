import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../../components/ModalWrapper';
import { actions, types as NetworkDiscoveryTypes } from '../../../../reducers/networkDiscovery';
import { connect } from 'react-redux';

function DeleteNetworkDiscoveryModal(props) {
    const [networkDiscoveryText, setNetworkDiscoveryText] = useState('');
    const [networkDiscoveryType, setNetworkDiscoveryType] = useState('');
    const [networkDiscoveryId, setNetworkDiscoveryId] = useState('');
    const [networkDiscoveryData, setNetworkDiscoveryData] = useState('');
    const [portMappingCount, setPortMappingCount] = useState(0);
    
    const { modalLoading } = props;

    function handleDelete() {
        switch (networkDiscoveryType) {
            case NetworkDiscoveryTypes.DELETE_MAC_ADDRESS:
                return props.submitDeleteMacAddress(props.modalData.id, props.modalData.parentId);
            case NetworkDiscoveryTypes.DELETE_BSSID:
                return props.submitDeleteBssid(props.modalData.id, props.modalData.parentId);
            case NetworkDiscoveryTypes.DELETE_IP_RANGE:
                return props.submitDeleteIpRange(props.modalData.id, props.modalData.parentId);
            case NetworkDiscoveryTypes.DELETE_LLDP:
                return props.submitDeleteLldp(props.modalData.id, props.modalData.parentId);
            case NetworkDiscoveryTypes.DELETE_PORT:
                return props.submitDeletePort(props.modalData.id, props.modalData.networkSwitch.id, props.modalData.sortBy);
            default:
                return null;
        }
    }

    useEffect(() => {
        switch (props.currentModal) {
            case NetworkDiscoveryTypes.DELETE_MAC_ADDRESS:
                setNetworkDiscoveryText('a MAC address');
                setNetworkDiscoveryId('macAddressName');
                setNetworkDiscoveryData(props.modalData.macAddress);
                setNetworkDiscoveryType(NetworkDiscoveryTypes.DELETE_MAC_ADDRESS);
                break;
            case NetworkDiscoveryTypes.DELETE_BSSID:
                setNetworkDiscoveryText('a BSSID');
                setNetworkDiscoveryId('bssidName');
                setNetworkDiscoveryData(props.modalData.bssid);
                setNetworkDiscoveryType(NetworkDiscoveryTypes.DELETE_BSSID);
                break;
            case NetworkDiscoveryTypes.DELETE_IP_RANGE:
                if (props.modalData.isPublic)
                    setNetworkDiscoveryText('the following Public IP Range:');
                else 
                    setNetworkDiscoveryText('an IP Range.');
                setNetworkDiscoveryId('ipRangeName');
                setNetworkDiscoveryData(`${props.modalData.ipAddressLow} to ${props.modalData.ipAddressHigh}`);
                setNetworkDiscoveryType(NetworkDiscoveryTypes.DELETE_IP_RANGE);
                break;
            case NetworkDiscoveryTypes.DELETE_LLDP:
                setNetworkDiscoveryText('a Chassis Mapping');
                setNetworkDiscoveryId('lldpName');
                setNetworkDiscoveryData(props.modalData.chassisId);
                setNetworkDiscoveryType(NetworkDiscoveryTypes.DELETE_LLDP);
                setPortMappingCount(props.modalData.portMappingCount !== undefined ? props.modalData.portMappingCount : 0);
                break;
            case NetworkDiscoveryTypes.DELETE_PORT:
                setNetworkDiscoveryText('a Port Mapping');
                setNetworkDiscoveryId('portName');
                setNetworkDiscoveryData(props.modalData.portId);
                setNetworkDiscoveryType(NetworkDiscoveryTypes.DELETE_PORT);
                break;
            default:
                break;
        }
    }, []);
    
    return(
        <div>
            {modalLoading && <LinearProgress/>}
            <Grid container 
                    direction="column" 
                    justify="center"
                    alignItems="center"
                    spacing={16} >
                <Grid item >
                    <p>
                        {networkDiscoveryType === NetworkDiscoveryTypes.DELETE_LLDP ? (
                            <>
                                Please confirm that you would like to delete {networkDiscoveryText} with <b>{portMappingCount}</b> Port mappings.
                            </>
                        ):(
                            <>
                                Please confirm that you would like to delete {networkDiscoveryText}.
                            </>
                        )}
                    </p>
                </Grid>
                <Grid item >
                    <b id={networkDiscoveryId}>{networkDiscoveryData}</b>
                </Grid>
                <Grid item >
                    <Button id="deleteBtn"
                            variant="contained" 
                            color="secondary" 
                            onClick={handleDelete}>
                        DELETE
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        submitDeleteMacAddress: (id, parentId) => dispatch(actions.deleteMacAddressRequest(id, parentId)),
        submitDeleteBssid: (id, parentId) => dispatch(actions.deleteBssidRequest(id, parentId)),
        submitDeleteIpRange: (id, parentId) => dispatch(actions.deleteIpRangeRequest(id, parentId)),
        submitDeleteLldp: (id, parentId) => dispatch(actions.deleteLldpRequest(id, parentId)),
        submitDeletePort: (id, parentId, sortBy) => dispatch(actions.deletePortRequest(id, parentId, sortBy))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper
)(DeleteNetworkDiscoveryModal);