import { call, put, takeLatest, all, select, takeEvery, cancelled, take, cancel, fork } from 'redux-saga/effects';
import CompanyAPI  from '../apis/companyApi'; 
import { actions as AppActions, selectNestedTablePageSize, selectNestedTableCurrentPage, selectSearchTerm, selectTableProps } from '../reducers/app';
import { actions as OrganizationsActions,
		 types as Organizations, makeSelectOrganizationsList, selectSubtenantsByParentId } from '../reducers/organizations';
import LicensingServiceApi from '../apis/licensingServiceApi';
import HomepageAPI from '../apis/homepageApi';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';
import { currentCompanyLevel } from '../reducers/auth';
import CompanyFeaturesAPI from '../apis/companyFeaturesApi';


function* getOrganizationList(action) {
	let tasks = []
	try {
		const response = yield call(CompanyAPI.getOrganizationList, action.page, action.pageSize, action.searchTerm, action.sortBy, action.sortDirection);
		yield put(OrganizationsActions.organizationsLoaded(response.data, response.headers['x-pagination-count']));
		try {
			for(var i = 0; i < response.data.length;) {
				// forking background tasks lets us cancel sagas manually if our current
				// saga ever gets cancelled -> 
				// user switches large page (100 orgs) 
				// but all company stats are still loading, we need to cancel all of them
				// doing batches of 6 has better performance than batch of 100
				var j = 0;
				for(; j < 6 && i + j < response.data.length; j++) {
					const org = response.data[i + j];
					
					tasks.push(
						yield fork(
							getCompanyStats, 
							OrganizationsActions.getCompanyStats(org.id, 'ORGANIZATIONS')
						)
					);
					tasks.push(
						yield fork(
							getLicensingStats, 
							OrganizationsActions.getLicensingStats(org.id, null)
						)
					);
				}

				let counter = 0;			
				while (counter < j) {
					// we need to block here because we want this current saga to stay alive 
					// so that we can cancel running tasks
					yield take('COMPANY_SUMMARY_COMPLETE');
					counter++;
				}
				i += j;
				tasks = [] // reset batch of tasks
			}
		} finally {
			if (yield cancelled()) {
				for(var i = 0; i < tasks.length; i++) {
					yield cancel(tasks[i]);
				}
			}
		}
	} catch (error) {
		yield put(OrganizationsActions.organizationsLoadingError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getOrganizationsByParentId(action) {
	try {
		const response = yield call(CompanyAPI.getOrganizationsByParentId, action.parentId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.sortDirection);	
		yield put(OrganizationsActions.getOrganizationsByParentIdSuccess(action.parentId, response.data, response.headers['x-pagination-count']));
		let tasks = []
		try {
			for(var i = 0; i < response.data.length;) {
				// forking background tasks lets us cancel sagas manually if our current
				// saga ever gets cancelled -> 
				// user switches large page (100 orgs) 
				// but all company stats are still loading, we need to cancel all of them
				// doing batches of 6 has better performance than batch of 100
				var j = 0;
				for(; j < 6 && i + j < response.data.length; j++) {
					const org = response.data[i + j];
					tasks.push(
						yield fork(
							getCompanyStats, 
							OrganizationsActions.getCompanyStats(org.id, 'ORGANIZATIONS')
						)
					);
					tasks.push(
						yield fork(
							getLicensingStats, 
							OrganizationsActions.getLicensingStats(org.id, null)
						)
					);
				}

				let counter = 0;			
				while (counter < j) {
					// we need to block here because we want this current saga to stay alive 
					// so that we can cancel running tasks
					yield take('COMPANY_SUMMARY_COMPLETE');
					counter++;
				}
				i += j;
				tasks = [] // reset batch of tasks
			}
		} finally {
			if (yield cancelled()) {
				for(var i = 0; i < tasks.length; i++) {
					yield cancel(tasks[i]);
				}
			}
		}
	} catch (error) {
		yield put(OrganizationsActions.organizationsLoadingError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getSubtenantsRequest(action) {
	if (action.sortBy === undefined || action.sortDirection === undefined ){
		action.sortBy = "name"
		action.sortDirection = "ASC"
	}
	try {
		const response = yield call(CompanyAPI.getOrganizationsByParentId, action.parentId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.sortDirection);
		yield put(OrganizationsActions.getSubtenantsSuccess(action.parentId, response.data, response.headers['x-pagination-count']))

		let tasks = []
		try {
			for(var i = 0; i < response.data.length;) {
				// forking background tasks lets us cancel sagas manually if our current
				// saga ever gets cancelled -> 
				// user switches large page (100 orgs) 
				// but all company stats are still loading, we need to cancel all of them
				// doing batches of 6 has better performance than batch of 100
				var j = 0;
				for(; j < 6 && i + j < response.data.length; j++) {
					const org = response.data[i + j];
					tasks.push(
						yield fork(
							getCompanyStats, 
							OrganizationsActions.getCompanyStats(org.id, 'SUBTENANTS', action.parentId, action.page)
						)
					);
					tasks.push(
						yield fork(
							getLicensingStats, 
							OrganizationsActions.getLicensingStats(org.id, action.parentId)
						)
					);
				}
				let counter = 0;			
				while (counter < j) {
					// we need to block here because we want this current saga to stay alive 
					// so that we can cancel running tasks
					yield take('COMPANY_SUMMARY_COMPLETE');
					counter++;
				}
				i += j;
				tasks = [] // reset batch of tasks
			}
		} finally {
			if (yield cancelled()) {
				for(var i = 0; i < tasks.length; i++) {
					yield cancel(tasks[i]);
				}
			}
		}
	} catch (error) {
		yield put(OrganizationsActions.getSubtenantsError(action.parentId));
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getCompanyStats(action) {
	try {
		const response = yield call(HomepageAPI.getCompanySummary, action.orgId, action.cancelTokenSource);
		yield put({ type: 'COMPANY_SUMMARY_COMPLETE' });

		let orgList = yield select(makeSelectOrganizationsList());
		let subtenantList = yield select(selectSubtenantsByParentId(action.parentId));
		orgList = orgList && orgList.toJS();
		subtenantList = subtenantList && subtenantList.toJS();

		if (action.orgLevel === 'SUBTENANTS') orgList = subtenantList;

		let cloneObj;
		let foundOrg;

		for (const org of orgList) {
			if (org.id === action.orgId) {
				cloneObj = {...org};
				cloneObj.companyStats = response.data;
				foundOrg = org;
				break;
			}
		}

		if (foundOrg) {
			const index = orgList.indexOf(foundOrg);
			orgList.splice(index, 1, cloneObj);
		}

		if (action.orgLevel === 'ORGANIZATIONS') {
			yield put(OrganizationsActions.getOrganizationsByParentIdSuccess(action.parentId, orgList, response.headers['x-pagination-count']));
		} else {
			yield put(OrganizationsActions.getSubtenantsSuccess(action.parentId, orgList, response.headers['x-pagination-count']))
		}
	} catch (error) {
		yield put(OrganizationsActions.organizationsLoadingError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* getLicensingStats(action) {
	try {
		const response = yield call(LicensingServiceApi.getLicenseCount, action.orgId);
		
		yield put({ type: 'COMPANY_LICENSES_COMPLETE' });
		
		let orgList = yield select(makeSelectOrganizationsList());
		let subtenantList = yield select(selectSubtenantsByParentId(action.orgId));
		
		orgList = orgList.toJS();
		subtenantList = subtenantList && subtenantList.toJS();

		let cloneObj;
		let foundOrg;

		if (!subtenantList) {
			for (const org of orgList) {
				if (org.id === action.orgId) {
					cloneObj = {...org};
					cloneObj.licensingStats = response.data;
					foundOrg = org;
					break;
				}
			}

			if (foundOrg) {
				const index = orgList.indexOf(foundOrg);
				orgList.splice(index, 1, cloneObj);
			}
	
			yield put(OrganizationsActions.getOrganizationsByParentIdSuccess(action.orgId, orgList, response.headers['x-pagination-count']))
		} else {
			for (const org of subtenantList) {
				if (org.id === action.orgId) {
					cloneObj = {...org};
					cloneObj.licensingStats = response.data;
					foundOrg = org;
					break;
				}
			}

			if (foundOrg) {
				const index = subtenantList.indexOf(foundOrg);
				subtenantList.splice(index, 1, cloneObj);
			}
	
			yield put(OrganizationsActions.getSubtenantsSuccess(action.parentId, subtenantList, response.headers['x-pagination-count']))
		}
	} catch (error) {
		yield put(OrganizationsActions.organizationsLoadingError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addOrganization(action) {
	try {
		const tableProps = yield select(selectTableProps());
		const nestedTablePageSize = yield select(selectNestedTablePageSize());
		const nestedTableCurrentPage = yield select(selectNestedTableCurrentPage());

		yield put(AppActions.setModalLoading());
		var response = yield call(CompanyAPI.addOrganization, action.data);
	
		if(action.data.emergencyCallAssist){
			CompanyFeaturesAPI.addCompanyFeatures(response.data.id, 'EMERGENCY_CALL_ASSIST');
		}
		
		yield put(AppActions.setModalClose());
		if(action.data.parentId) {
			yield put(OrganizationsActions.getSubtenantsRequest(action.data.parentId, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.sortDirection))
		} else {
			yield put(OrganizationsActions.getOrganizationsByParentId(null, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.sortDirection));
		}
	} catch(error) {
		console.log(error);
		let options = {};
		if(error.response.data.type === "http://cirrus.redskytech.com/errors/cerServer/ipAddress/duplicate") {
			options.backendFieldError = {
			 name: 'The key entered is not unique. Please enter another key.'
			};
		  }
		  if(!options.backendFieldError) {
			options.displayTopErrorMessage = true;
			options.topErrorMessage = error.response.data.title;
		  }
		  error.response.data.errorOptions = options;
		yield put(AppActions.setModalErrorOptions(errorHandler(error)));
	}
}

function* editOrganization(action) {
	try {
		const tableProps = yield select(selectTableProps());
		const searchTerm = yield select(selectSearchTerm());

		yield put(AppActions.setModalLoading());
		var response = yield call(CompanyAPI.editOrganization, action.data, action.row);
		if(action.data.emergencyCallAssist){
			CompanyFeaturesAPI.addCompanyFeatures(response.data.id, 'EMERGENCY_CALL_ASSIST');
		}
		else{
			CompanyFeaturesAPI.removeCompanyFeatures(response.data.id, 'EMERGENCY_CALL_ASSIST');
		}
		yield put(AppActions.setModalClose());
		if(action.row.parentId) {
			yield put(OrganizationsActions.getSubtenantsRequest(action.row.parentId, tableProps.page, tableProps.pageSize, searchTerm, tableProps.sortBy, tableProps.direction))
		} else {
			yield put(OrganizationsActions.getOrganizationsByParentId(null, tableProps.page, tableProps.pageSize, searchTerm, tableProps.sortBy, tableProps.sortDirection));
		}
	} catch(error) {
		let options = {};
		if(error.response.data.type === 'http://cirrus.redskytech.com/errors/company/invalidParent') {
			options.backendFieldError = {
				type: error.response.data.type,
				name: error.response.data.title
			};
		}
		if(error.response.data.type === "http://cirrus.redskytech.com/errors/cerServer/ipAddress/duplicate") {
			options.backendFieldError = {
			 name: 'The key entered is not unique. Please enter another key.'
			};
		}
		  if(!options.backendFieldError) {
			options.displayTopErrorMessage = true;
			options.topErrorMessage = error.response.data.title;
		  }
		  error.response.data.errorOptions = options;
		yield put(AppActions.setModalErrorOptions(errorHandler(error)));
	}
}

function* deleteOrganization(action) {
	try {
		const searchTerm = yield select(selectSearchTerm());
		const companyLevel = yield select(currentCompanyLevel());
		const tableProps = yield select(selectTableProps());

		yield put(AppActions.setModalLoading());
		yield call(CompanyAPI.deleteOrganization, action.data);
		yield put(AppActions.setModalClose());
		
		if (action.parentId) {
			if(!searchTerm) {
				yield put(OrganizationsActions.getSubtenantsRequest(action.parentId, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.sortDirection));
			} else {
				if (companyLevel === 'SYSTEM') {
					yield put(OrganizationsActions.getOrganizationsByParentId(null, tableProps.page, tableProps.pageSize, searchTerm, tableProps.sortBy, tableProps.sortDirection));
				} else {
					yield put(OrganizationsActions.getSubtenantsRequest(action.parentId, tableProps.page, tableProps.pageSize, searchTerm, tableProps.sortBy, tableProps.sortDirection));
				}
			}
		} else {
			if(!searchTerm) {
				yield put(OrganizationsActions.getOrganizationsByParentId(null, tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.sortDirection));
				
			} else {
				yield put(OrganizationsActions.getOrganizationsByParentId(null, tableProps.page, tableProps.pageSize, searchTerm, tableProps.sortBy, tableProps.sortDirection));
			}
		}
	} catch(error) {
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootOrganizationsSaga() {
	yield all([
		yield takeEvery(Organizations.GET_ORGANIZATIONS, getOrganizationList),
		yield takeEvery(Organizations.GET_COMPANY_STATS, getCompanyStats),
		yield takeEvery(Organizations.GET_LICENSING_STATS, getLicensingStats),
		yield takeLatest(Organizations.ADD_ORGANIZATION, addOrganization),
		yield takeLatest(Organizations.GET_ORGANIZATIONS_BY_PARENT_ID, getOrganizationsByParentId),
		yield takeLatest(Organizations.GET_SUBTENANTS_REQUEST, getSubtenantsRequest),
		yield takeLatest(Organizations.EDIT_ORGANIZATION, editOrganization),
		yield takeLatest(Organizations.DELETE_ORGANIZATION, deleteOrganization),
	]);
}

export default rootOrganizationsSaga; 