import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { compose } from 'redux';
import ModalWrapper from '../../../components/ModalWrapper';
import { actions } from '../../../reducers/deviceUsers';
import { connect } from 'react-redux';

class DeleteDeviceUserModal extends Component {

    handleDelete = () => {
        this.props.submitDeleteDeviceUser(this.props.modalData.device.deviceId, this.props.modalData.deviceUserId);
    }

    render() {
        const { modalLoading } = this.props;

        return(
            <div>
                {modalLoading && <LinearProgress/>}
                <Grid container 
                        direction="column" 
                        justify="center"
                        alignItems="center"
                        spacing={16} >
                    <Grid item >
                        <p>
                            Please confirm that you would like to delete the following device
                        </p>
                    </Grid>
                    <Grid item >
                        <span id="deviceUserName">Name: <b>{this.props.modalData.device.name}</b></span> <br/>
                    </Grid>
                    <Grid item >
                        <Button id="deleteBtn"
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleDelete}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {

        submitDeleteDeviceUser: (deviceId, userId) => dispatch(actions.deleteDeviceUserDevicesRequest(deviceId, userId))
    }
}

export default compose(
    connect(null,mapDispatchToProps),
    ModalWrapper
)(DeleteDeviceUserModal);