import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { actions as AppActions, selectUserWelcomeEmailEnabled } from '../../reducers/app';
import { types } from '../../reducers/users';
import history from '../../utils/history';
import { createStructuredSelector } from 'reselect';
import { actions as UserActions } from '../../reducers/users';
import handleCopyToClipboard from '../../utils/handleCopyToClipboard';

function UserEditMenu(props) {
	let options;
	const ITEM_HEIGHT = 48;
	
	const [anchorEl, setAnchorEl] = useState(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [copyToClipboardFailed, setCopyToClipboardFailed] = useState(false);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

		setSnackbarOpen(false);
    };

	const handleMenuItemClick = (option) => {
		handleClose();
		if (props.userId === props.row.original.id) {
			switch (option) {
				case 'Edit':
					history.push({
						pathname: '/myAccount'
					});
					break;
				case 'Resend Welcome Email':
					props.resendUserWelcomeEmail(props.row.original);
					break;
				case 'Copy UUID to Clipboard':
					handleCopyToClipboard(props.row.original.id, setSnackbarOpen, setCopyToClipboardFailed);
					break;
				default:
					return null;
			}	
		} else {
			switch (option) {
				case 'Edit':
					props.setCurrentModal(types.EDIT_USER, props.row.original);
					break;
				case 'Resend Welcome Email':
					props.resendUserWelcomeEmail(props.row.original);
					break;
				case 'Delete':
					props.setCurrentModal(types.DELETE_USER, props.row.original);
					break;
				case 'Copy UUID to Clipboard':
					handleCopyToClipboard(props.row.original.id, setSnackbarOpen, setCopyToClipboardFailed);
					break;
				default:
					return null;
			}
		}
	};

	const buildEditMenu = () => {
		options = [];
		
		if (props.checkCustomPermission('CUSTOM_EDIT_ADMIN', props.user)) {
			options.push('Edit');
		}

		if(props.userWelcomeEmailEnabled && props.row.original.role.name !== 'EON User'){
			options.push('Resend Welcome Email');
		}
		if(props.userId !== props.row.original.id && props.checkCustomPermission('CUSTOM_DELETE_ADMIN', props.user)){
			options.push('Delete');
		}
		options.push('Copy UUID to Clipboard');
	}

	const open = Boolean(anchorEl);
	buildEditMenu();

	return (
		<div>
			<IconButton
				id={`administrator-action-menu-button-${props.row.index}`}
				aria-label="More"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id={`administrator-action-menu-${props.row.index}`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 6.5,
						minWidth: 200,
					},
				}}
			>
					{options.map((option, index) => (
						<MenuItem id={`administrator-item-${props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => handleMenuItemClick(option, event)}>
							{option}
						</MenuItem>
					))}
			</Menu>
			<Snackbar
				className={`snackbar-popup ${copyToClipboardFailed && 'error'}`}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
				message={<div>
					{copyToClipboardFailed ? 
						<span id="client-snackbar">
							The code/ID was not copied to your clipboard. Please copy manually:<br/>
							{props.row.original.id}
						</span>
						:
						<span id="client-snackbar">
							<CheckCircleIcon className={"classes.icon classes.iconVariant"} />
							The code/ID has been copied to your clipboard
						</span>
					}
				</div>}
				action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					onClick={handleSnackbarClose}
				>
					<CloseIcon />
				</IconButton>,
				]}
			/>
		</div>
	);
}

const mapStateToProps = () => createStructuredSelector({
	userWelcomeEmailEnabled: selectUserWelcomeEmailEnabled()
});

const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
	  resendUserWelcomeEmail: (data) => dispatch(UserActions.resendUserWelcomeEmail(data))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditMenu);
