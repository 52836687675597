import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { modalCurrent,
        modalLoading,
        modalErrorDisplay,
        modalErrorMessage,
        modalOpen,
        modalData,
        modalErrorOptions } from '../../reducers/app';
import { actions as appActions, types as AppTypes, modalConfirm } from  '../../reducers/app';
/* OrganizationPage  Modals */
import { types as OrganizationTypes } from '../../reducers/organizations';
import AddEditOrganizationModal from './OrganizationModals/addEditOrganizationModal';
import DeleteOrganizationModal from './OrganizationModals/deleteOrganizationModal';
/* UsersPage Modals */
import { types as UsersTypes } from '../../reducers/users';
import AddEditUserModal from './UserModals/addEditUserModal';
import DeleteUserModal from './UserModals/deleteUserModal';
/* EonUsersPage Modals */
import { types as EonUsersTypes } from '../../reducers/eonUsers';
import AddEditEonUserModal from './EonUserModals/addEditEonUserModal';
import DeleteEonUserModal from './EonUserModals/deleteEonUserModal';
/* Building Modals */
import { types as LocationsTypes } from '../../reducers/locations';
import AddBuildingModal from './BuildingModals/AddBuilding';
import EditBuildingModal from './BuildingModals/EditBuilding';
import DeleteBuildingModal from './BuildingModals/DeleteBuilding';
import AddLocationModal from './BuildingModals/AddLocation';
import DeleteLocationModal from './BuildingModals/DeleteLocation';
import DeleteLocationCERModal from './BuildingModals/DeleteLocationCER';
import EditLocationModal from './BuildingModals/EditLocation';
import EditLocationCERModal from './BuildingModals/EditLocationCER';
import RescanAddress from './BuildingModals/RescanAddress';
/* Network Discovery Modals */
import { types as NetworkDiscoveryTypes, makeSelectPublicIpRange } from '../../reducers/networkDiscovery';
import AddEditNetworkDiscovery from './NetworkDiscoveryModals/shared/addEditNetworkDiscoveryModal';
import DeleteNetworkDiscovery from './NetworkDiscoveryModals/shared/deleteNetworkDiscoveryModal';
/* Users Modals */
import { types as DeviceUsersTypes } from '../../reducers/deviceUsers';
import AddEditDeviceUser from './DeviceUsersModals/AddEditDeviceUser';
import DeleteDeviceUser from './DeviceUsersModals/DeleteDeviceUser';
import DeleteDeviceUserLocation from './DeviceUsersModals/DeleteDeviceUserLocation';
import DeleteDeviceUserDevices from './DeviceUsersModals/DeleteDeviceUserDevices';
import ShowHeldRequestMessage from './DeviceUsersModals/ShowHeldRequestMessage';
import AlternateIdListModal from './DeviceUsersModals/AlternateIdListModal';
import DeviceUsersDeviceDetails from './DeviceUsersModals/DeviceUsersDeviceDetails';
/* Alert Subscriptions Modals */
import { types as AlertSubscriptionTypes } from '../../reducers/alerts';
import AddEditAlertsSubscriptions from './AlertsModals/Subscriptions/AddEditAlertsSubscriptions';
import DeleteAlertsSubscriptions from './AlertsModals/Subscriptions/DeleteAlertsSubscriptions';
import AlertsSubscriptionsRecipients from './AlertsModals/Subscriptions/AlertsSubscriptionsRecipients';
import DeleteAlertsTemplates from './AlertsModals/Templates/DeleteAlertsTemplates';
import PreviewAlertsTemplates from './AlertsModals/Templates/PreviewAlertsTemplates';
import CopyAlertsTemplates from './AlertsModals/Templates/CopyAlertsTemplates';
import SelectAlertsTemplates from './AlertsModals/Templates/SelectAlertsTemplates';
import AddAlertsTemplates from './AlertsModals/Templates/AddAlertsTemplates';
import EditAlertsTemplates from './AlertsModals/Templates/EditAlertsTemplates';
import WelcomeEmailTemplateError from './AlertsModals/Templates/WelcomeEmailTemplateError';
/* Call History Modals */
import { types as CallHistoryTypes } from '../../reducers/callHistory';
import DetailsCallHistory from './CallHistoryModals/DetailsCallHistory';
import CallHistoryRecording from './CallHistoryModals/CallHistoryRecording';
import CallHistorySipInvite from './CallHistoryModals/CallHistorySipInvite';
/* Manage Licenses Modals */
import { types as ManageLicensesTypes } from '../../reducers/manageLicenses';
import AddLicense from './ManageLicensesModals/addLicenseModal';
import DeleteLicense from './ManageLicensesModals/deleteLicense';
/* Sip Modals */
import { types as SipTypes } from '../../reducers/sip';
import AddSip from './SipModals/AddSip';
import EditSip from './SipModals/EditSip';
import DeleteSip from './SipModals/DeleteSip';
/* Pstn Modals */
import { types as PstnTypes } from '../../reducers/pstn';
import AddPstn from './PstnModals/AddPstn';
import EditPstn from './PstnModals/EditPstn';
import DeletePstn from './PstnModals/DeletePstn';
/* Pidflo Devices Modals */
import { types as PidfloDevicesTypes } from '../../reducers/pidfloDevices';
import AddEditPidfloDevices from './PidfloDevicesModals/AddEditPidfloDevices';
import DeletePidfloDevices from './PidfloDevicesModals/DeletePidfloDevices';
/* Call Monitoring Modals */
import { types as CallMonitoringTypes } from '../../reducers/callMonitoring';
import CallMonitoringNumbers from './CallMonitoringModals/CallMonitoringNumbers';
import DeleteCallMonitoring from './CallMonitoringModals/DeleteCallMonitoring';
import AddEditCallMonitoring from './CallMonitoringModals/AddEditCallMonitoring';
/* Events Modals */
import { types as EventsTypes } from '../../reducers/events';
import EventsDescription from './EventsModals/EventsDescription';
/* Imports Modals */
import { types as ImportTypes } from '../../reducers/importing';
import AddImportModal from './ImportsModals/AddImportModal';
import DeleteImportModal from './ImportsModals/deleteImportModal';
import ImportingErrorDetails from './ImportsModals/ImportingErrorDetails';
import TemplateFilesModal from './ImportsModals/TemplateFilesModal';
/* Held Device Modals */
import { types as HeldDeviceTypes } from '../../reducers/heldDevices';
import DeleteHeldDeviceModal from './HeldDevicesModals/DeleteHeldDeviceModal';
import DetailsHeldDevicesModal from './HeldDevicesModals/DetailsHeldDevices';
import HeldDevicesHeldRequest from './HeldDevicesModals/HeldDevicesHeldRequest';
/* Generate Report */
import { types as ReportsTypes } from '../../reducers/reports';
import GenerateReportsModal from './ReportsModals/GenerateReport';
import DetailsReportModal from './ReportsModals/DetailsReport';
/* Scheduled Reports */
import { types as ScheduledReportsTypes } from '../../reducers/scheduledReports';
import ScheduleReportsModal from './ReportsModals/ScheduleReport';
import EditScheduleReportsModal from './ReportsModals/EditScheduleReport';
import DeleteScheduleReportModal from './ReportsModals/DeleteScheduleReport';
/* My Account Modal */
import MyAccountModal from './MyAccountModals/MyAccountModal';
/* Organization Management */
import { types as OrganizationManagementTypes } from '../../reducers/organizationManagement';
import AddCERServerModal from './OrganizationManagementModals/AddCERServerModal';
import EditCERServerModal from './OrganizationManagementModals/EditCERServerModal';
import DeleteCERServerModal from './OrganizationManagementModals/DeleteCERServerModal';
import DeleteMassNotifcationModal from './OrganizationManagementModals/DeleteMassNotificationModal'
/* Notification Integrations */
import { types as NotificationIntegrationTypes } from '../../reducers/notificationIntegrations';
import AddEditInformaCastEntityModal from './NotificationIntegrationModals/AddEditInformaCastEntityModal';
import DeleteInformaCastEntityModal from './NotificationIntegrationModals/DeleteInformaCastEntityModal';
/* API Key Modals */
import { types as ApiKeyTypes } from '../../reducers/apiAccessKey'
import AddApiKeyModal from "./ApiKeyModals/AddApiKey";
import DeleteApiKeyModal from './ApiKeyModals/DeleteApiKey';

/* Details Box */
import BuildingDetails from './BuildingModals/details/BuildingDetails';
import LocationDetails from './BuildingModals/details/LocationDetails';

/* Address Management Modals */
import { types as AddressManagementTypes } from '../../reducers/addressManagement';
import AddressManagementRescan from './AddressManagementModals/AddressManagementRescan';

const ModalConductor = props => {
    const storedIsPublicIpRange = JSON.parse(sessionStorage.getItem('isPublicIpRange'));

    switch (props.currentModal) {
        case OrganizationTypes.ADD_ORGANIZATION:
            return <AddEditOrganizationModal title="Add Organization" {...props}/>;
        case OrganizationTypes.EDIT_ORGANIZATION:
            return <AddEditOrganizationModal title="Edit Organization" {...props} />;
        case OrganizationTypes.DELETE_ORGANIZATION:
            return <DeleteOrganizationModal isSubTenant={false} title="Delete Organization" {...props} />;
        case UsersTypes.ADD_USER:
			return <AddEditUserModal title="Add Administrator" {...props}/>;
		case UsersTypes.EDIT_USER:
			return <AddEditUserModal title="Edit Administrator" {...props}/>;
		case UsersTypes.DELETE_USER:
			return <DeleteUserModal title="Delete Administrator" {...props}/>;
        case EonUsersTypes.ADD_EON_USER:
			return <AddEditEonUserModal title="Add EON User" {...props}/>;
		case EonUsersTypes.EDIT_EON_USER:
			return <AddEditEonUserModal title="Edit EON User" {...props}/>;
		case EonUsersTypes.DELETE_EON_USER:
			return <DeleteEonUserModal title="Delete EON User" {...props}/>;
        case LocationsTypes.ADD_BUILDING:
            return <AddBuildingModal title="Add Building" {...props} />;
        case LocationsTypes.EDIT_BUILDING:
            return <EditBuildingModal title="Edit Building" {...props} />;
        case LocationsTypes.DELETE_BUILDING:
            return <DeleteBuildingModal title="Delete Building" {...props} />;
        case LocationsTypes.RESCAN_ADDRESS_BUILDING:
            return <RescanAddress title="Rescan Address" {...props} />;
        case LocationsTypes.RESCAN_ADDRESS_LOCATION:
            return <RescanAddress title="Rescan Address" {...props} />;
        case LocationsTypes.ADD_LOCATION:
            return <AddLocationModal title="Add Location" {...props} />;
        case LocationsTypes.DELETE_LOCATION:
            return <DeleteLocationModal title="Delete Location" {...props} />;
        case LocationsTypes.DELETE_LOCATION_CER:
            return <DeleteLocationCERModal title="Delete CER Location" {...props} />;
        case LocationsTypes.EDIT_LOCATION:
            return <EditLocationModal title="Edit Location" {...props} />;
        case LocationsTypes.EDIT_LOCATION_CER:
            return <EditLocationCERModal title="Edit CER Location" {...props} />;
        case NetworkDiscoveryTypes.ADD_MAC_ADDRESS:
            return <AddEditNetworkDiscovery title="Add MAC Address Mapping" {...props} />;
        case NetworkDiscoveryTypes.EDIT_MAC_ADDRESS:
            return <AddEditNetworkDiscovery title="Edit MAC Address Mapping" {...props} />;
        case NetworkDiscoveryTypes.DELETE_MAC_ADDRESS:
            return <DeleteNetworkDiscovery title="Delete MAC Address Mapping" {...props} />;
        case NetworkDiscoveryTypes.ADD_BSSID:
            return <AddEditNetworkDiscovery title="Add BSSID Mapping" {...props} />;
        case NetworkDiscoveryTypes.EDIT_BSSID:
            return <AddEditNetworkDiscovery title="Edit BSSID Mapping" {...props} />;
        case NetworkDiscoveryTypes.DELETE_BSSID:
            return <DeleteNetworkDiscovery title="Delete BSSID Mapping" {...props} />;
        case NetworkDiscoveryTypes.ADD_IP_RANGE:
            return <AddEditNetworkDiscovery title={`Add IP Range ${storedIsPublicIpRange ? '' : 'Mapping'}`} {...props} />;
        case NetworkDiscoveryTypes.EDIT_IP_RANGE:
            return <AddEditNetworkDiscovery title={`Edit IP Range ${storedIsPublicIpRange ? '' : 'Mapping'}`} {...props} />;
        case NetworkDiscoveryTypes.DELETE_IP_RANGE:
            return <DeleteNetworkDiscovery title={`Delete IP Range ${storedIsPublicIpRange ? '' : 'Mapping'}`} {...props} />;
        case NetworkDiscoveryTypes.ADD_LLDP:
            return <AddEditNetworkDiscovery title="Add Chassis Mapping" {...props} />;
        case NetworkDiscoveryTypes.EDIT_LLDP:
            return <AddEditNetworkDiscovery title="Edit Chassis Mapping" {...props} />;
        case NetworkDiscoveryTypes.DELETE_LLDP:
            return <DeleteNetworkDiscovery title="Delete Chassis Mapping" {...props} />;
        case NetworkDiscoveryTypes.ADD_PORT:
            return <AddEditNetworkDiscovery title="Add Port Mapping" {...props} />;
        case NetworkDiscoveryTypes.EDIT_PORT:
            return <AddEditNetworkDiscovery title="Edit Port Mapping" {...props} />;
        case NetworkDiscoveryTypes.DELETE_PORT:
            return <DeleteNetworkDiscovery title="Delete Port Mapping" {...props} />;
        case DeviceUsersTypes.ADD_DEVICE_USER:
            return <AddEditDeviceUser title="Add User" {...props} />;
        case DeviceUsersTypes.DELETE_DEVICE_USER:
            return <DeleteDeviceUser title="Delete User" {...props} />;
        case DeviceUsersTypes.EDIT_DEVICE_USER:
            return <AddEditDeviceUser title="Edit User" {...props} />;
        case DeviceUsersTypes.DELETE_DEVICE_USER_DEVICE:
            return <DeleteDeviceUserDevices title="Delete Device" {...props} />;
        case DeviceUsersTypes.SHOW_HELD_REQUEST_MESSAGE:
            return <ShowHeldRequestMessage title="HELD+ Request Message" {...props} />;
        case DeviceUsersTypes.DELETE_DEVICE_USER_LOCATION:
            return <DeleteDeviceUserLocation title="Delete Location" {...props} />;
        case DeviceUsersTypes.DEVICE_USERS_DEVICES_DETAILS:
            return <DeviceUsersDeviceDetails title="Device Details" {...props} />;
        case AlertSubscriptionTypes.ADD_ALERT_SUBSCRIPTION:
            return <AddEditAlertsSubscriptions title="Add Subscription" {...props} />;
        case AlertSubscriptionTypes.DELETE_ALERT_SUBSCRIPTION:
            return <DeleteAlertsSubscriptions title="Delete Subscription" {...props} />;
        case AlertSubscriptionTypes.EDIT_ALERT_SUBSCRIPTION:
            return <AddEditAlertsSubscriptions title="Edit Subscription" {...props} />;
        case AlertSubscriptionTypes.ALERT_SUBSCRIPTION_RECIPIENTS:
            return <AlertsSubscriptionsRecipients title="List of Recipients" {...props} />;
        case AlertSubscriptionTypes.DELETE_ALERT_TEMPLATE:
            return <DeleteAlertsTemplates title="Delete User-Defined Alert Template" {...props} />;
        case AlertSubscriptionTypes.PREVIEW_ALERT_TEMPLATE:
            return <PreviewAlertsTemplates title="Preview Template" {...props} />;
        case CallHistoryTypes.CALL_HISTORY_DETAILS:
            return <DetailsCallHistory title="Call History Details" {...props} />;
        case CallHistoryTypes.CALL_HISTORY_RECORDING:
            return <CallHistoryRecording title="Call History Recording" {...props} />;
        case CallHistoryTypes.CALL_HISTORY_SIP_INVITE:
            return <CallHistorySipInvite title="SIP Invite" {...props} />;
        case AlertSubscriptionTypes.COPY_ALERT_TEMPLATE:
            return <CopyAlertsTemplates title="Template Name" {...props} />;
        case AlertSubscriptionTypes.SELECT_ALERT_TEMPLATE:
            return <SelectAlertsTemplates title="Select Alert/Email Type" {...props} />;
        case AlertSubscriptionTypes.ADD_ALERT_TEMPLATE:
            return <AddAlertsTemplates {...props} />;
        case AlertSubscriptionTypes.EDIT_ALERT_TEMPLATE:
            return <EditAlertsTemplates {...props} />;
        case ManageLicensesTypes.ADD_LICENSE:
            return <AddLicense title="Add License" {...props} />;
        case ManageLicensesTypes.DELETE_LICENSE:
            return <DeleteLicense title="Delete License" {...props} />;
        case SipTypes.ADD_SIP:
            return <AddSip title="Add SIP Peer" {...props} />;
        case SipTypes.EDIT_SIP:
            return <EditSip title="Edit SIP Peer" {...props} />;
        case SipTypes.DELETE_SIP:
            return <DeleteSip title="Delete SIP Peer" {...props} />;
        case PstnTypes.ADD_PSTN:
            return <AddPstn title="Add PSTN Peer" {...props} />;
        case PstnTypes.EDIT_PSTN:
            return <EditPstn title="Edit PSTN Peer" {...props} />;
        case PstnTypes.DELETE_PSTN:
            return <DeletePstn title="Delete PSTN Peer" {...props} />;
        case PidfloDevicesTypes.ADD_PIDFLO_DEVICES:
            return <AddEditPidfloDevices title="Add PIDF-LO Device" {...props} />;
        case PidfloDevicesTypes.EDIT_PIDFLO_DEVICES:
            return <AddEditPidfloDevices title="Edit PIDF-LO Device" {...props} />;
        case PidfloDevicesTypes.DELETE_PIDFLO_DEVICES:
            return <DeletePidfloDevices title="Delete PIDF-LO Device" {...props} />;
        case CallMonitoringTypes.ADD_CALL_MONITORING:
            return <AddEditCallMonitoring title="Add Call Monitoring" {...props} />;
        case CallMonitoringTypes.DELETE_CALL_MONITORING:
            return <DeleteCallMonitoring title="Delete Call Monitoring" {...props} />;
        case CallMonitoringTypes.EDIT_CALL_MONITORING:
            return <AddEditCallMonitoring title="Edit Call Monitoring" {...props} />;
        case CallMonitoringTypes.CALL_MONITORING_NUMBERS:
            return <CallMonitoringNumbers title="List of Phone Numbers" {...props} />;
        case EventsTypes.EVENTS_DESCRIPTION:
            return <EventsDescription title="Events Description" {...props} />;
        case ImportTypes.ADD_IMPORT:
            return <AddImportModal title="Add Import" {...props} />;
        case ImportTypes.DELETE_IMPORT:
            return <AddImportModal title="Bulk Delete" {...props} />;
        case ImportTypes.DELETE_IMPORTING:
            return <DeleteImportModal title="Delete Import" {...props} />;
        case ImportTypes.TEMPLATE_FILES_IMPORTING:
            return <TemplateFilesModal title="Template Files" {...props} />;
        case ImportTypes.IMPORTING_ERROR_DETAILS:
            return <ImportingErrorDetails title="Failed Importing Details" maxWidth={false} {...props} />;
        case HeldDeviceTypes.DELETE_HELD_DEVICE:
            return <DeleteHeldDeviceModal title="Delete HELD Device" {...props} />;
        case HeldDeviceTypes.HELD_DEVICES_DETAILS:
            return <DetailsHeldDevicesModal title="HELD Devices Details" {...props} />;
        case HeldDeviceTypes.HELD_DEVICES_HELD_REQUEST:
            return <HeldDevicesHeldRequest title={props.modalData.deviceType === 'HELD' ? 'View HELD Request' : 'View HELD+ Request'} {...props} />;
        case ReportsTypes.GENERATE_REPORT:
            return <GenerateReportsModal title={`Generate ${props.modalData.name} Report`} {...props} />;
        case ReportsTypes.SCHEDULE_REPORT:
            return <ScheduleReportsModal title={`Schedule ${props.modalData.name} Report`} {...props} />;
        case ScheduledReportsTypes.EDIT_SCHEDULED_REPORT:
            return <EditScheduleReportsModal title="Edit Scheduled Report" {...props} />;
        case ScheduledReportsTypes.SCHEDULED_REPORTS_DETAILS:
            return <DetailsReportModal title="Scheduled Report Details" {...props} />;
        case ScheduledReportsTypes.DELETE_SCHEDULED_REPORT:
            return <DeleteScheduleReportModal title="Delete Scheduled Report" {...props} />;
        case AppTypes.MY_ACCOUNT_MODAL:
            return <MyAccountModal title="My Account" {...props} />;
        case OrganizationManagementTypes.ADD_CER_SERVERS:
            return <AddCERServerModal title="Add CER Server IP Address" {...props} />;
        case OrganizationManagementTypes.EDIT_CER_SERVERS:
            return <EditCERServerModal title="Edit CER Server IP Address" {...props} />;
        case OrganizationManagementTypes.DELETE_CER_SERVERS:
            return <DeleteCERServerModal title="Delete CER Server IP Address" {...props} />;
        case OrganizationManagementTypes.DELETE_INTEGRATION_CRED:
            return <DeleteMassNotifcationModal title="Reset Incident Communications API Access Information" {...props} />;
        case NotificationIntegrationTypes.ADD_INFORMACAST_ENTITY:
            return <AddEditInformaCastEntityModal title="Add InformaCast 911 Alert Entity" {...props} />;
        case NotificationIntegrationTypes.EDIT_INFORMACAST_ENTITY:
            return <AddEditInformaCastEntityModal title="Edit InformaCast 911 Alert Entity" {...props} />;
        case NotificationIntegrationTypes.DELETE_INFORMACAST_ENTITY:
            return <DeleteInformaCastEntityModal title="Delete InformaCast 911 Alert Entity" {...props} />;
        case LocationsTypes.BUILDING_DETAILS:
            return <BuildingDetails title="Building Details" {...props} />;
        case LocationsTypes.LOCATION_DETAILS:
            return <LocationDetails title="Location Details" {...props} />;
        case AlertSubscriptionTypes.EMAIL_TEMPLATE_ERROR:
            return <WelcomeEmailTemplateError title="Error" {...props} />;
        case DeviceUsersTypes.ALTERNATE_ID_DETAILS:
            return <AlternateIdListModal title={"List of Device User IDs for " + props.modalData.row.email} {...props}/>;
        case ApiKeyTypes.ADD_API_ACCESS_KEY:
            return <AddApiKeyModal title={"Generate API Key"} {...props}/>;
        case ApiKeyTypes.ADD_API_ACCESS_KEY_SUCCESS:
            return <AddApiKeyModal title={"Generate API Key"} {...props}/>;
        case ApiKeyTypes.DELETE_API_KEY:
            return <DeleteApiKeyModal title={"Delete API Key"} {...props}/>;
        case AddressManagementTypes.ADDRESS_MANAGEMENT_RESCAN:
            return <AddressManagementRescan title={"Rescan Address"} {...props}/>;
        default:
            return null;
    }
}

const mapStateToProps = createStructuredSelector({
    modalOpen: modalOpen(),
    currentModal: modalCurrent(),
    modalLoading: modalLoading(),
    modalErrorDisplay: modalErrorDisplay(),
    modalErrorMessage: modalErrorMessage(),
    modalData: modalData(),
    modalConfirm: modalConfirm(),
    modalErrorOptions: modalErrorOptions(),
});

const mapDispatchToProps = dispatch => bindActionCreators(appActions,dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalConductor);