import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { actions as AppActions } from '../../reducers/app';
import { types } from '../../reducers/networkDiscovery';
import { currentColumn } from '../../containers/PortListTable/portListTable';
import NetworkDiscoveryAPI from '../../apis/networkDiscoveryApi';

class LldpEditMenu extends Component {
	options = [
		'Edit',
		'Delete',
		'Add Port Mapping'
	];
	
	ITEM_HEIGHT = 48;
	state = {
		anchorEl: null,
	};

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleMenuItemClick = async (option) => {
		this.handleClose();
		const lldpId = this.props.row.original.id;
		switch (option) {
			case 'Edit':
				this.props.setCurrentModal(types.EDIT_LLDP, this.props.row.original);
				break;
			case 'Delete':
				const portCount = this.props.row.original.portCount;
				const isExpanded = this.props.expandedRows[this.props.row.index] || false; 
				if (isExpanded) {
					this.props.setCurrentModal(types.DELETE_LLDP, { 
						...this.props.row.original, 
						portMappingCount: portCount
					});
				} else {
					const sortBy = this.props.currentSortColumn?.id || 'location';
					const direction = this.props.currentSortColumn?.desc ? 'DESC' : 'ASC';
					try {
						const response = await NetworkDiscoveryAPI.getPort(lldpId, sortBy, direction);
						const fetchedPortCount = response?.data?.length || 0;
						this.props.setCurrentModal(types.DELETE_LLDP, { 
							...this.props.row.original, 
							portMappingCount: fetchedPortCount 
						});
					} catch (error) {
						console.error("Error fetching port mappings:", error);
						this.props.setCurrentModal(types.DELETE_LLDP, { 
							...this.props.row.original, 
							portMappingCount: 0 
						});
					}
				}
				break;
			case 'Add Port Mapping':
				if (currentColumn().data[0]) 
					this.props.row.original.sortBy = currentColumn().data[0]
				this.props.setCurrentModal(types.ADD_PORT, this.props.row.original);
				break;
			default:
				return null;
		}
	};

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<div>
				<IconButton
					id={`lldp-action-menu-button-${this.props.row.index}`}
					aria-label="More"
					aria-haspopup="true"
					onClick={this.handleClick}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id={`lldp-action-menu-${this.props.row.index}`}
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleClose}
					PaperProps={{
						style: {
							maxHeight: this.ITEM_HEIGHT * 6.5,
							minWidth: 200,
						},
					}}
				>
					{this.options.map((option, index) => (
						<MenuItem id={`lldp-item-${this.props.row.index}-${index}`} className={option === 'Delete' ? 'action-menu-delete' : ''} key={option} selected={option === 'Pyxis'} onClick={event => this.handleMenuItemClick(option, event)}>
							{option}
						</MenuItem>
					))}
				</Menu>
			</div>
		);
	}
}

LldpEditMenu.propTypes = {
	row: PropTypes.object,
	setCurrentModal: PropTypes.func
};


const mapDispatchToProps = dispatch => {
  return {
      setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
  }
};

export default connect(null, mapDispatchToProps)(LldpEditMenu);
